import { checkAccess, getAppName } from "../../utils/accessControl";
import { cognito } from "../../utils/aws";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const login = (phone, password, searchParams) => async (dispatch) => {
  try {
    const redirect_uri = searchParams.get("redirect_uri");
    const appName = getAppName(redirect_uri); // uncomment before deploying

    // const appName = "admin"; // comment before deploying

    if (appName) {
      const data = await cognito.login(
        phone,
        password,
        searchParams.get("client_id")
      );

      const access = checkAccess(appName, data.idToken.payload);

      console.log(data.idToken.payload);

      if (access) {
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: {
            user: data.idToken.payload,
          },
        });

        const token = data.getAccessToken().getJwtToken();
        const idToken = data.getIdToken().getJwtToken();
        const refreshToken = data.refreshToken.token;

        cookies.set("token", token, {
          domain: process.env.REACT_APP_COOKIES_DOMAIN,
          path: "/",
        });
        cookies.set("idToken", idToken, {
          domain: process.env.REACT_APP_COOKIES_DOMAIN,
          path: "/",
        });
        cookies.set("refreshToken", refreshToken, {
          domain: process.env.REACT_APP_COOKIES_DOMAIN,
          path: "/",
        });

        if (
          searchParams.get("redirect_uri").includes("localhost") &&
          ["DEV", "STAGE"].includes(process.env.REACT_APP_ENVIRONMENT)
        ) {
          window.location.href =
            searchParams.get("redirect_uri") +
            "?token=" +
            token +
            "&idToken=" +
            idToken +
            "&refreshToken=" +
            refreshToken;
        } else {
          window.location.href = searchParams.get("redirect_uri");
        }
      } else {
        localStorage.clear();
        dispatch({
          type: "LOGIN_FAILURE",
          error: {
            message: "This Role is Unauthorized to Access the Client App",
          },
        });
      }
    } else {
      dispatch({ type: "LOGIN_FAILURE", error: { message: "Invalid App" } });
    }
  } catch (error) {
    dispatch({ type: "LOGIN_FAILURE", error });
  }
};

export const logout = (client_id) => async (dispatch) => {
  try {
    const response = await cognito.logout(client_id, cookies.get("token"));
    console.log("RESPONSE:", response);
    if (response) {
      dispatch({ type: "LOGOUT" });
    }
  } catch (error) {
    dispatch({ type: "LOGOUT_FAILURE", error });
  }
};
