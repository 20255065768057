import { useState, useEffect } from "react";

import "../css/login.css";
import "../css/mobileLogin.css";
import mainLogo from "../logo.png";
import flag from "../india-flag-icon.png";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { login } from "../redux/actions/authActions";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, Link, Navigate } from "react-router-dom";
import { loginSchema } from "../schemas";
import { useLocation } from "react-router-dom";

import { useFormik } from "formik";
import axios from "axios";
import { otpSchemaSignup } from "../schemas";
import { cognito } from "../utils/aws";
import { checkAccess, getAppName } from "../utils/accessControl";
import "../css/stepper.css";
import Loader from "../assets/Frame 1000003082.png";
import Failure from "../assets/failure.svg";
import Success from "../assets/suuccess1.svg";
import Screen1 from "../components/Screen1";
import Screen2 from "../components/Screen2";
import Screen3 from "../components/Screen3";
import Screen4 from "../components/Screen4";
import Screen5 from "../components/Screen5";
import Screen6 from "../components/Screen6";
import Cookies from "universal-cookie";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const cookies = new Cookies();

function Login() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const redirectUri = queryParams.get("redirect_uri");

  const isReactNativeApp = window.isReactNativeApp;
  const isAndroid = window.isAndroid;
  const isIOS = window.isIOS;
  const isMobileBrowser =
    /Mobi|Android/i.test(navigator.userAgent) && !isReactNativeApp;
  let environment;

  const notify = () => {
    toast.info(
      <p style={{ fontSize: "0.8rem", fontStyle: "normal" }}>
        Make sure registered mobile number is available in the handset to
        trigger the SMS..! <br /> Standard carrier charges apply for the SMS..!
      </p>,
      {
        autoClose: 5000, // Optional: to auto-close the toast after 3 seconds
      }
    );
  };

  const [test, setTest] = useState(true);
  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState([]);
  const [encryptedMessage, setEncryptedMessage] = useState();
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const isLoading = useSelector((state) => state.auth.isLoading);
  const loginError = useSelector((state) => state.auth.error);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(59);
  const [timeoutId, setTimeoutId] = useState("");
  const [confirmCode, setConfirmCode] = useState("");
  const [apiResponseReceived, setApiResponseReceived] = useState(false);
  const [progressStep, setProgressStep] = useState(0);
  const [appNameMobile, setAppNameMobile] = useState(null);
  const [mobileLoginError, setMobileLoginError] = useState(null);
  const [simSelect, setSimSelect] = useState("");
  const [simNumber, setSimNumber] = useState();
  const [strokeColorSim1, setStrokeColorSim1] = useState("#BBBBBC");
  const [strokeColorSim2, setStrokeColorSim2] = useState("#BBBBBC");
  const [sendMsg, setSendMsg] = useState(false);
  const [simCount, setSimCount] = useState(0);
  const [selectedSvg, setSelectedSvg] = useState(null);
  const [osVersion, setOsVersion] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [socketConnection, setSocketConnection] = useState(false);
  const [socketConnection2, setSocketConnection2] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const reduxState = localStorage.getItem("reduxState");

  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();

  const userAgent = window.navigator.userAgent;
  // Check if it's an Android app based on the user agent
  const isAndroidApp = /Android/i.test(navigator.userAgent);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  useEffect(() => {
    const messageHandler = (event) => {
      try {
        let receivedData = event.data;

        // Check if the received data is a string and parse it
        if (typeof receivedData === "string") {
          receivedData = JSON.parse(receivedData);
        }

        // Extract appName and simCount
        const { appName, simCount } = receivedData;
        setAppNameMobile(appName);

        setSimCount(simCount);
      } catch (error) {
        console.error("Error parsing message data", error);
      }
    };

    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, []);

  const table = [
    { "App Name": "MedVol Pharmacy", "App Description": "Pharmacy/Delegate" },
    { "App Name": "MedVol Assist", "App Description": "Company Field" },
    { "App Name": "MedVol Channel", "App Description": "Stockist" },
  ];

  function getApiBody(value) {
    if (typeof value !== "string") {
      // Return an empty object if the value is not a valid string
      return {};
    }

    let mappedObject;

    switch (value.toLowerCase()) {
      case "pharmacy":
        mappedObject = table.find(
          (item) => item["App Name"] === "MedVol Pharmacy"
        );
        break;
      case "assist":
        mappedObject = table.find(
          (item) => item["App Name"] === "MedVol Assist"
        );
        break;
      case "channel":
        mappedObject = table.find(
          (item) => item["App Name"] === "MedVol Channel"
        );
        break;
      default:
        // Return an empty object if the value does not match any case
        return {};
    }

    if (!mappedObject) {
      // Return an empty object if no matching app is found
      return {};
    }

    // Constructing API body
    const apiBody = {
      appName: mappedObject["App Name"],
      appDescription: mappedObject["App Description"],
    };

    return apiBody;
  }

  const onSubmitDesktop = async (e) => {
    e.preventDefault();
    dispatch({ type: "SET_LOADING" });
    setErrorMessage("");
    try {
      login(`+91${phone}`, password, searchParams)(dispatch);
    } catch (error) {
      console.log(error);
      setErrorMessage(error?.response?.data?.message);
    }
  };

  const loginMob = () => {
    setStep(2);
  };
  const loginMob1 = () => {
    sendSimNumber1();
  };
  const requestMobileVerify = async () => {
    console.log("requestMobileVerify is called");
    setErrorMessage("");
    setMobileLoginError("");

    const apiBody = getApiBody(appNameMobile);
    console.log(apiBody);
    try {
      const response = await axios.post(
        // `${process.env.REACT_APP_OTP_URL}/mobile-verification-dev`,
        `${process.env.REACT_APP_OTP_URL}/mobile-verification`,
        {
          request: "true",
          username: `91${phone}`,
          timestamp: new Date(),
          appName: apiBody.appName,
          appDescription: apiBody.appDescription,
        }
      );

      console.log("RESPONSE:", response);
      if (response?.data?.adminVerified) {
        setStep(8);
        setProgressStep(1);
        await mobileLogin();
        setProgressStep(2);
      } else {
        startWebSocket();
        console.log("requestMobileVerify calling");
        console.log("res => ", response);
        setEncryptedMessage(response?.data?.message);
        console.log("encrypted message => ", response?.data?.message);
        const encMsg = response?.data?.message;
        // Send the response to the native app using postMessage
        setTimeout(() => {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ type: "encryptedMessage", encMsg })
          );
        }, 3000);
        setApiResponseReceived(true);
      }
    } catch (err) {
      console.log("Error:", err);
      setMobileLoginError(err?.response?.data?.message);
      setErrorMessage(err?.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };

  const startWebSocket = () => {
    const newSocket = new WebSocket(
      `${process.env.REACT_APP_WEBSOCKET_URL}?mobile=91${phone}`
    );
    newSocket.onopen = () => {
      console.log("WebSocket connection established");
      setSocket(newSocket);
      setProgressStep(1);
      // notify();
      setStep(6);
    };
    newSocket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setMessages((prevMessages) => [...prevMessages, data]);
      console.log("WEBSOCKET EVENT:", event);
      const response = JSON.parse(event.data);
      setSocketConnection(true);
      newSocket.close();
      if (response?.error === true) {
        setErrorMessage(response?.message);
        setMobileLoginError(response?.message);
        setStep(7);
      } else if (response?.message === "Failed") {
        setStep(7);
      } else if (response?.message === "Success") {
        setStep(8);
        setTimeout(() => {
          mobileLogin();
        }, 1000);
      }
    };
    newSocket.onclose = () => {
      console.log("WebSocket connection closed");
      if (socketConnection === false) {
        console.log("Socket connection closed");
        setSocketConnection2(true);
      }
    };

    // setTimeout(() => {
    //   newSocket.close();
    //   if (socketConnection === false) {
    //     console.log("Socket connection closed");
    //     setSocketConnection2(true);
    //   }
    // }, 2000);
    return () => {
      newSocket.close();
    };
  };

  const mobileLogin = async () => {
    const response = await cognito.mobileLogin(
      `+91${phone}`,
      `mobile:${appNameMobile}`
    );
    console.log("response of mobile login => ", response);
    if (!response.error) {
      setStep(9);
      setTimeout(() => {
        if (
          window.ReactNativeWebView &&
          window.ReactNativeWebView.postMessage
        ) {
          window.ReactNativeWebView.postMessage(
            JSON.stringify({ type: "storeResponse", response })
          );
        } else {
          console.error("ReactNativeWebView is not available.");
        }
      }, 1000);
    } else {
      setStep(10);
      setErrorMessage(response?.error);
      setMobileLoginError(response?.error);
    }
  };

  // // Click handler to change the stroke color
  // const handleSvgClick = () => {
  //   setStrokeColor(strokeColor === '#BBBBBC' ? 'blue' : '#BBBBBC');
  // };

  // useEffect(() => {
  //   if (step === 8) {
  //     setTimeout(() => {
  //       mobileLogin();
  //     }, 1000);
  //   }
  // }, [step]);

  useEffect(() => {
    setErrorMessage("");
  }, [phone, password]);

  useEffect(() => {
    const authDetails = reduxState
      ? JSON.parse(reduxState).auth
      : { loggedIn: false };
    console.log("authDetails", authDetails);
    const redirect_uri = searchParams.get("redirect_uri");

    const cook = cookies.get("idToken");
    console.log({ cook });
    const idToken = localStorage.key(2);

    const temp = async () => {
      console.log("CognitoIdentityServiceProvider is not present in cookies.");
      localStorage.clear();
      // await logout(searchParams.get("client_id"))(dispatch);
      cookies.remove("token", {
        domain: process.env.REACT_APP_COOKIES_DOMAIN,
        path: "/",
      });
      cookies.remove("idToken", {
        domain: process.env.REACT_APP_COOKIES_DOMAIN,
        path: "/",
      });
      cookies.remove("refreshToken", {
        domain: process.env.REACT_APP_COOKIES_DOMAIN,
        path: "/",
      });
      window.location.href =
        "/?redirect_uri=" +
        searchParams.get("redirect_uri") +
        "&client_id=" +
        searchParams.get("client_id");
    };

    if (!cook && idToken) {
      temp();
    } else if (authDetails.loggedIn === true && redirect_uri) {
      const appName = getAppName(redirect_uri); // uncomment before deploying

      // const appName = "admin"; // comment before deploying
      const access = checkAccess(appName, authDetails.user);
      if (access) {
        const token = localStorage.getItem(
          `CognitoIdentityServiceProvider.${searchParams.get("client_id")}.${
            authDetails.user.sub
          }.accessToken`
        );
        const idToken = localStorage.getItem(
          `CognitoIdentityServiceProvider.${searchParams.get("client_id")}.${
            authDetails.user.sub
          }.idToken`
        );
        const refreshToken = localStorage.getItem(
          `CognitoIdentityServiceProvider.${searchParams.get("client_id")}.${
            authDetails.user.sub
          }.refreshToken`
        );
        cookies.set("token", token, {
          domain: process.env.REACT_APP_COOKIES_DOMAIN,
          path: "/",
        });
        cookies.set("idToken", idToken, {
          domain: process.env.REACT_APP_COOKIES_DOMAIN,
          path: "/",
        });
        cookies.set("refreshToken", refreshToken, {
          domain: process.env.REACT_APP_COOKIES_DOMAIN,
          path: "/",
        });
        if (
          searchParams.get("redirect_uri").includes("localhost") &&
          ["DEV", "STAGE"].includes(process.env.REACT_APP_ENVIRONMENT)
        ) {
          window.location.href =
            searchParams.get("redirect_uri") +
            "?token=" +
            token +
            "&idToken=" +
            idToken +
            "&refreshToken=" +
            refreshToken;
        } else {
          window.location.href = searchParams.get("redirect_uri");
        }
      } else {
        setLoading(false);
        dispatch({
          type: "LOGIN_FAILURE",
          error: {
            message: "This Role is Unauthorized to Access the Client App",
          },
        });
      }
    }
  }, [reduxState, searchParams]);

  useEffect(() => {
    if (loginError) {
      setErrorMessage(loginError);
    }
  }, [loginError]);

  useEffect(() => {
    if (mobileLoginError) {
      // setErrorMessage(loginError);
      setMobileLoginError(mobileLoginError);
    }
  }, [mobileLoginError]);

  const formikOTP = useFormik({
    initialValues: {
      confirmCode: "",
    },
    validationSchema: otpSchemaSignup,
    validateOnMount: true,
  });

  const formik = useFormik({
    initialValues: {
      mobile: "",
      password: "",
    },
    validationSchema: loginSchema,
    validateOnMount: true,
  });

  const handleInputChange = (event) => {
    const input = event.target.value;
    // Remove non-numeric characters using a regular expression
    const numericInput = input.replace(/[^0-9]/g, "");
    setPhone(numericInput);
    formik.setFieldValue("mobile", event.target.value);
  };

  const selectSim1 = () => {
    setSimSelect("Sim 1");
    setSimNumber(1);
  };

  const selectSim2 = () => {
    setSimSelect("Sim 2");
    setSimNumber(2);
  };

  const sendSimNumber = () => {
    if (
      typeof window.ReactNativeWebView !== "undefined" &&
      window.ReactNativeWebView.postMessage
    ) {
      if (simNumber === 1) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "sim1", simNumber })
        );
      }
      if (simNumber === 2) {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({ type: "sim2", simNumber })
        );
      }
    } else {
      console.error("ReactNativeWebView is not available");
    }
    setStep(3);
    setTimeout(() => {
      requestMobileVerify();
    }, 2000);
  };

  const sendSimNumber1 = () => {
    const simNumber = 1;
    window.ReactNativeWebView.postMessage(
      JSON.stringify({ type: "sim1", simNumber })
    );
    setStep(3);
    setTimeout(() => {
      requestMobileVerify();
    }, 2000);
  };

  const selectSvg = (svgId) => {
    setSelectedSvg(svgId);
  };

  // Send data from React web app to react native app
  // const sendDataToReactNative = () => {
  //   const data = "Dhananjay from Web App";
  //   window.postMessage(data, "*");
  // };

  if (isReactNativeApp) {
    if (isAndroid) {
      environment = "react-native-android";
    } else if (isIOS) {
      environment = "react-native-ios";
    }
  } else if (isMobileBrowser) {
    environment = "mobile-browser";
  } else {
    environment = "desktop-browser";
  }

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    formik.setFieldValue("password", e.target.value);
  };

  // if (test === true) {
  if (isReactNativeApp || userAgent.includes("MyReactNativeAppWebView")) {
    return (
      <div>
        <ToastContainer />

        {loading ? (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            Loading...
          </div>
        ) : (
          <div className="container">
            {step === 1 && (
              <div className="subContainer">
                <div className="header">
                  <div className="logoContainer">
                    <img src={mainLogo} alt="Logo" className="logo" />
                  </div>
                  <div className="titleContainer">
                    <h2 className="title">Sign up</h2>
                  </div>
                </div>
                <form
                  className="mobileForm"
                  onSubmit={() => {
                    if (environment === "react-native-ios") {
                      // for working on desktop
                      // if (true) {
                      setStep(3);
                      setTimeout(() => {
                        requestMobileVerify();
                      }, 2000);
                    } else {
                      if (simCount === 1) {
                        loginMob1();
                      } else {
                        loginMob();
                      }
                    }
                  }}
                >
                  <div className="formContainer">
                    <div className="lableContainer">
                      <label className="lable">Phone Number</label>
                    </div>
                    <div className="inputContainer">
                      <input
                        maxLength={10}
                        type="text"
                        inputMode="decimal"
                        onChange={handleInputChange}
                        value={phone}
                        onBlur={formik.handleBlur}
                        onKeyPress={(e) => {
                          const charCode = e.which ? e.which : e.keyCode;
                          if (
                            charCode > 31 &&
                            (charCode < 48 || charCode > 57)
                          ) {
                            e.preventDefault();
                          }
                        }}
                        name="mobile"
                        id="mobile"
                        className={
                          formik.touched.mobile && formik.errors.mobile
                            ? "error"
                            : ""
                        }
                        autoComplete="off"
                      />
                    </div>
                    {formik.touched.mobile && formik.errors.mobile && (
                      <div className="error-message">
                        {formik.errors.mobile}
                      </div>
                    )}

                    {errorMessage && (
                      <p className="error-message">{errorMessage}</p>
                    )}

                    <div className="buttonContainer">
                      <button
                        className={formik.isValid ? "submit " : "submit1"}
                        // disabled={formik.isValid}
                      >
                        Submit
                      </button>

                      {/* <button className="submit">Submit</button> */}
                    </div>
                  </div>
                </form>
                {/* <div className="header">
                  <p className="alreadyAcc">
                    Already a registered user? <a>Login</a>
                  </p>
                </div> */}
              </div>
            )}

            {step === 2 && (
              <div className="subContainer">
                <div className="header">
                  <div className="logoContainer">
                    <img src={mainLogo} alt="Logo" className="logo" />
                  </div>
                  <div className="titleContainer">
                    <h2 className="title">Verify your mobile</h2>
                    {simCount > 0 && (
                      <p className="textArea">
                        We will trigger an SMS from your registered phone number
                      </p>
                    )}
                  </div>
                </div>
                {simCount > 0 ? (
                  <div className="svgContainer">
                    <div className="firstSvg">
                      {/* <img src={SimSVG} alt="Your SVG"  /> */}

                      <svg
                        id="svg1"
                        width="70"
                        height="97"
                        viewBox="0 0 70 97"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => {
                          selectSvg("svg1");
                          selectSim1();
                        }}
                      >
                        <path
                          id="clickablePath"
                          d="M1 90V7C1 3.68629 3.6863 1 7.00001 1H46.0411C47.8786 1 49.6148 1.84196 50.7526 3.28482L67.7114 24.7912C68.5461 25.8497 69 27.1584 69 28.5064V90C69 93.3137 66.3137 96 63 96H7C3.68629 96 1 93.3137 1 90Z"
                          // stroke={strokeColorSim1}
                          stroke={
                            selectedSvg === "svg1" ? "#7f56d9" : "#BBBBBC"
                          }
                          strokeWidth="2"
                          tabIndex="0"
                        />
                        <text
                          className="svgTitle"
                          x="50%"
                          y="50%"
                          dominantBaseline="middle"
                          textAnchor="middle"
                          stroke={
                            selectedSvg === "svg1" ? "#7f56d9" : "#BBBBBC"
                          }
                        >
                          Sim 1
                        </text>
                      </svg>
                    </div>
                    <div className="secondSvg">
                      <svg
                        id="svg2"
                        width="70"
                        height="97"
                        viewBox="0 0 70 97"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        onClick={() => {
                          selectSvg("svg2");
                          selectSim2();
                        }}
                      >
                        <path
                          id="clickablePath"
                          d="M1 90V7C1 3.68629 3.6863 1 7.00001 1H46.0411C47.8786 1 49.6148 1.84196 50.7526 3.28482L67.7114 24.7912C68.5461 25.8497 69 27.1584 69 28.5064V90C69 93.3137 66.3137 96 63 96H7C3.68629 96 1 93.3137 1 90Z"
                          stroke={
                            selectedSvg === "svg2" ? "#7f56d9" : "#BBBBBC"
                          }
                          strokeWidth="2"
                          tabIndex="0"
                        />
                        <text
                          className="svgTitle"
                          x="50%"
                          y="50%"
                          dominantBaseline="middle"
                          textAnchor="middle"
                          stroke={
                            selectedSvg === "svg2" ? "#7f56d9" : "#BBBBBC"
                          }
                        >
                          Sim 2
                        </text>
                      </svg>
                    </div>
                  </div>
                ) : (
                  <>
                    <p className="textArea">
                      SIM card not available in your handset. <br />
                      Insert SIM card and try again..!
                    </p>
                  </>
                )}

                {simCount > 0 ? (
                  <div className="buttonContainer">
                    {selectedSvg && (
                      <button className="sendSMS" onClick={sendSimNumber}>
                        Send SMS
                      </button>
                    )}
                  </div>
                ) : null}
              </div>
            )}

            {step === 3 && (
              <div className="verifySubContainer">
                <div className="verifyHeader">
                  <div className="verifylogoContainer">
                    <img src={mainLogo} alt="Logo" className="logo" />
                  </div>
                  <div className="verifytitleContainer">
                    <h2 className="verifytitle">Verifying user</h2>
                  </div>
                </div>
                {mobileLoginError ? (
                  <div className="stepperContainer">
                    <div className="leftpart">
                      <img className="failure" src={Failure} alt="loader" />
                    </div>
                    <div className="rightpart">
                      <span className="child1">{mobileLoginError}</span>
                    </div>
                  </div>
                ) : (
                  <div className="stepperContainer">
                    <div className="leftpart">
                      <img className="loader" src={Loader} alt="loader" />
                    </div>
                    <div className="rightpart">
                      <span className="child1">Verifying your number!</span>
                      {simCount === 1 || environment === "react-native-ios" ? (
                        <span className="child2">
                          An SMS has been triggered!
                        </span>
                      ) : (
                        <span className="child2">
                          An SMS has been triggered from{" "}
                          <span>{simSelect}</span>
                        </span>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}

            {step === 4 && (
              <div className="verifySubContainer">
                <div className="verifyHeader">
                  <div className="verifylogoContainer">
                    <img src={mainLogo} alt="Logo" className="logo" />
                  </div>
                  <div className="verifytitleContainer">
                    <h2 className="verifytitle">Verifying user</h2>
                    <br />
                  </div>
                </div>
                <div className="stepperContainer">
                  <div className="leftpart">
                    <img className="failure" src={Failure} alt="loader" />
                  </div>
                  <div className="rightpart">
                    <span className="child3">Unable to send SMS</span>
                    <span className="child4">
                      {" "}
                      <span>Click here</span> to enable SMS permissions.
                    </span>
                  </div>
                </div>
              </div>
            )}

            {step >= 5 && (
              <div className="verifySubContainer">
                <div className="verifyHeader">
                  <div className="verifylogoContainer">
                    <img src={mainLogo} alt="Logo" className="logo" />
                  </div>
                  <div className="verifytitleContainer">
                    <h2 className="verifytitle">Verifying user</h2>
                  </div>
                </div>
                <div className="steppergapcontainer">
                  {step === 5 && (
                    <Screen1 simSelect={simSelect} environment={environment} />
                  )}
                  {step === 6 && (
                    <>
                      <Screen2
                        simSelect={simSelect}
                        environment={environment}
                      />
                      {socketConnection2 === true
                        ? console.log("Socket connection starting again ")
                        : null}
                    </>
                  )}
                  {step === 7 && (
                    <Screen3
                      simCount={simCount}
                      simSelect={simSelect}
                      phone={phone}
                      environment={environment}
                      mobileLoginError={mobileLoginError}
                    />
                  )}
                  {step === 8 && (
                    <Screen4
                      simCount={simCount}
                      simSelect={simSelect}
                      environment={environment}
                    />
                  )}
                  {step === 9 && (
                    <Screen5
                      simCount={simCount}
                      simSelect={simSelect}
                      environment={environment}
                    />
                  )}
                  {step === 10 && (
                    <Screen6
                      simCount={simCount}
                      simSelect={simSelect}
                      environment={environment}
                      mobileLoginError={mobileLoginError}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
    // } else if (!redirectUri) {
    //   return <Navigate to="/access-blocked" />;
  } else {
    return (
      <div>
        {isLoading ? (
          <div
            style={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          >
            Loading...
          </div>
        ) : (
          <div className="container">
            <div className="forms-container">
              <div className="signin-signup">
                <form onSubmit={onSubmitDesktop} className="sign-in-form">
                  <img src={mainLogo} alt="Logo" className="logo" />
                  <br />

                  <h2 className="title">Sign In</h2>
                  <div className="input-field required">
                    <i className="fa fa-phone" aria-hidden="true"></i>

                    <span className="contryCode">
                      <img src={flag} alt="Logo" className="flag" /> +91{" "}
                    </span>
                    <input
                      maxLength={10}
                      type="text"
                      placeholder="Mobile Number"
                      onChange={(e) => {
                        setPhone(e.target.value);
                        formik.setFieldValue("mobile", e.target.value);
                      }}
                      value={phone}
                      onBlur={formik.handleBlur}
                      onKeyPress={(e) => {
                        const charCode = e.which ? e.which : e.keyCode;
                        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                          e.preventDefault();
                        }
                      }}
                      name="mobile"
                      id="mobile"
                      className={
                        formik.touched.mobile && formik.errors.mobile
                          ? "error"
                          : ""
                      }
                    />
                  </div>
                  {formik.touched.mobile && formik.errors.mobile && (
                    <>
                      <div className="error-message">
                        {formik.errors.mobile}
                      </div>
                    </>
                  )}
                  <div className="input-field">
                    <i className="fa fa-key" aria-hidden="true"></i>
                    <span></span>
                    <input
                      type={passwordVisible ? "text" : "password"}
                      placeholder="Password"
                      onChange={handlePasswordChange}
                      onBlur={formik.handleBlur}
                      name="password"
                      id="password"
                      className={
                        formik.touched.password && formik.errors.password
                          ? "error"
                          : ""
                      }
                    />
                    <button
                      type="button"
                      onClick={togglePasswordVisibility}
                      aria-label={
                        passwordVisible ? "Hide password" : "Show password"
                      }
                      style={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        height: "100%",
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        // padding: "0 1rem",
                        paddingRight: "1rem",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      {passwordVisible ? <FaEyeSlash /> : <FaEye />}
                      {/* Or use Unicode characters: */}
                      {/* {passwordVisible ? '🙈' : '👁'} */}
                    </button>
                  </div>
                  {formik.touched.password && formik.errors.password && (
                    <>
                      <div className="error-message">
                        {formik.errors.password}
                      </div>
                    </>
                  )}

                  <button
                    type="submit"
                    className={formik.isValid ? "btn solid " : "loginButton"}
                    disabled={!formik.isValid}
                  >
                    Login
                  </button>

                  {errorMessage && (
                    <>
                      <p className="error-message">{errorMessage}</p>
                    </>
                  )}
                  <Link
                    to={`/register?client_id=${searchParams.get(
                      "client_id"
                    )}&redirect_uri=${searchParams.get("redirect_uri")}`}
                  >
                    {" "}
                    Sign Up
                  </Link>
                  <Link
                    to={`/forgot?client_id=${searchParams.get(
                      "client_id"
                    )}&redirect_uri=${searchParams.get("redirect_uri")}`}
                  >
                    {" "}
                    Forgot Or Reset Password?
                  </Link>
                </form>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Login;
